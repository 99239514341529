.answers {
  padding: 5px 0px 20px 0px;
}

.responses .title {
  padding: 0 0 25px 0;
}

.total p {
  font-size: 16px;
}/*# sourceMappingURL=Answers.css.map */