html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img, video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
} /* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Regular"), url("/src/css/fonts/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Italic"), url("/src/css/fonts/Poppins-Italic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Thin"), url("/src/css/fonts/Poppins-Thin.woff") format("woff");
}
@font-face {
  font-family: "Poppins Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Thin Italic"), url("/src/css/fonts/Poppins-ThinItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraLight"), url("/src/css/fonts/Poppins-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraLight Italic"), url("/src/css/fonts/Poppins-ExtraLightItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Light";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Light"), url("/src/css/fonts/Poppins-Light.woff") format("woff");
}
@font-face {
  font-family: "Poppins Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Light Italic"), url("/src/css/fonts/Poppins-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Medium"), url("/src/css/fonts/Poppins-Medium.woff") format("woff");
}
@font-face {
  font-family: "Poppins Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Medium Italic"), url("/src/css/fonts/Poppins-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins SemiBold"), url("/src/css/fonts/Poppins-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Bold"), url("/src/css/fonts/Poppins-Bold.woff") format("woff");
}
@font-face {
  font-family: "Poppins Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Bold Italic"), url("/src/css/fonts/Poppins-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraBold"), url("/src/css/fonts/Poppins-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraBold Italic"), url("/src/css/fonts/Poppins-ExtraBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Black";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Black"), url("/src/css/fonts/Poppins-Black.woff") format("woff");
}
@font-face {
  font-family: "Poppins Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Black Italic"), url("/src/css/fonts/Poppins-BlackItalic.woff") format("woff");
}
.transactions-array-row {
  background: #eee;
  display: grid;
  grid-template-columns: 13% 13% 1fr 9% 9% 1fr 8%;
  border-radius: 10px;
  margin-bottom: 5px;
  color: #000;
}

.transactions-array-header {
  font-weight: 500;
}
.transactions-array-elem {
  padding: 10px 15px 10px 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
}
.transactions-array-elem p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}
.transactions-array-elem.array-tags {
  justify-content: flex-start;
  padding: 5px 10px;
}/*# sourceMappingURL=TransactionsArrayRow.css.map */