.satisfaction-answer {
  padding: 10px 0px;
}
.satisfaction-answer .checkbox-question {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-row-gap: 10px;
}
.satisfaction-answer .answer-item.checkbox-question-answer {
  display: grid;
  grid-template-columns: 80% 20%;
}
.satisfaction-answer .answer-item-element {
  display: flex;
}
.satisfaction-answer .checkbox {
  width: 22px;
  height: 22px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}
.satisfaction-answer .answer-question-label {
  font-size: 18px;
  padding-bottom: 10px;
}
.satisfaction-answer .answer-item-label {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  margin-right: 10px;
}
.satisfaction-answer .answer-answers {
  flex-wrap: wrap;
}/*# sourceMappingURL=SatisfactionAnswer.css.map */