html, body, p, ol, ul, li, dl, dt, dd, blockquote, figure, fieldset, legend, textarea, pre, iframe, hr, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

ul {
  list-style: none;
}

button, input, select {
  margin: 0;
}

html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img, video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
} /* #### Generated By: http://www.cufonfonts.com #### */
@font-face {
  font-family: "Poppins Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Regular"), url("/src/css/fonts/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Italic"), url("/src/css/fonts/Poppins-Italic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Thin"), url("/src/css/fonts/Poppins-Thin.woff") format("woff");
}
@font-face {
  font-family: "Poppins Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Thin Italic"), url("/src/css/fonts/Poppins-ThinItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraLight"), url("/src/css/fonts/Poppins-ExtraLight.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraLight Italic"), url("/src/css/fonts/Poppins-ExtraLightItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Light";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Light"), url("/src/css/fonts/Poppins-Light.woff") format("woff");
}
@font-face {
  font-family: "Poppins Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Light Italic"), url("/src/css/fonts/Poppins-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Medium"), url("/src/css/fonts/Poppins-Medium.woff") format("woff");
}
@font-face {
  font-family: "Poppins Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Medium Italic"), url("/src/css/fonts/Poppins-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins SemiBold"), url("/src/css/fonts/Poppins-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Bold"), url("/src/css/fonts/Poppins-Bold.woff") format("woff");
}
@font-face {
  font-family: "Poppins Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Bold Italic"), url("/src/css/fonts/Poppins-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraBold"), url("/src/css/fonts/Poppins-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: "Poppins ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins ExtraBold Italic"), url("/src/css/fonts/Poppins-ExtraBoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Poppins Black";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Black"), url("/src/css/fonts/Poppins-Black.woff") format("woff");
}
@font-face {
  font-family: "Poppins Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Poppins Black Italic"), url("/src/css/fonts/Poppins-BlackItalic.woff") format("woff");
}
.sidebar {
  display: grid;
  grid-template-rows: 75px 30px 1fr;
  max-width: 130px;
  min-width: 130px;
  height: 100%;
  padding: 25px 15px;
  color: #000;
  border-right: 1px solid rgba(170, 170, 170, 0.2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-width 0.6s ease-in;
}
.sidebar.active {
  max-width: 500px;
  transition: max-width 0.6s ease-in;
}
.sidebar-nav {
  width: 100%;
}
.sidebar-logo {
  width: 90px;
}
.sidebar-title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-nav {
  display: flex;
  justify-content: flex-end;
}

.toggle-nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-size: 20px;
  cursor: pointer;
  color: rgb(145, 145, 145);
}
.toggle-nav-btn:hover {
  color: #787878;
}

.sidebar-nav {
  padding: 25px 10px;
}

.sidebar-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.sidebar-elem {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-elem a {
  margin: 0 auto;
}

.sidebar-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: inherit;
  text-decoration: none;
  font-size: 14px;
}
text .sidebar-link:hover {
  color: #AAA;
}
.sidebar-link svg {
  font-size: 22px;
}
.sidebar-link .icon-link-text {
  padding-left: 15px;
}

.sidebar.active .sidebar-link {
  justify-content: flex-start;
}

.nav-icon {
  width: 40px;
  height: 40px;
}

.sidebar-params {
  display: inline-block;
  margin: 0 auto;
  font-size: 20px;
  width: 40px;
}
.sidebar-params-link {
  display: inline-block;
  width: 35px;
}
.sidebar-params-link svg {
  width: 100%;
}/*# sourceMappingURL=Sidebar.css.map */