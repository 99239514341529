.contacts .title {
  font-family: "Poppins Medium";
  font-size: 24px;
}

.actions {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 25px 0px 20px 0px;
}
.actions-left, .actions-right {
  width: 100%;
  display: flex;
}
.actions-right {
  justify-content: flex-end;
}
.actions .expand-btn {
  position: relative;
}/*# sourceMappingURL=Contacts.css.map */